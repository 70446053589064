@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display&family=Poppins&display=swap');

body {
  margin: 0;
  font-family: "Poppins", -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a, button {
  @apply duration-150;
  @apply ease-in-out;
}

.white {
  color: white !important;
}

.logo-green {
  color: #82b92b;
}

.bg-logo-green {
  background-color: #82b92b;
}

.home-bg {
  background-image: url("../images/homebg.jpg"), linear-gradient(rgba(0,0,0,0.6),rgba(0,0,0,0.6));
  background-blend-mode: overlay;
  @apply bg-cover bg-no-repeat bg-center overflow-hidden;
}

.home-hero-height {
  height: calc(100vh - 64px);
}

.playfair {
  font-family: "Playfair Display";
}

.max-w-footertext {
  max-width: 340px;
}

.text-shadow-green {
  text-shadow: 4px 4px rgb(110, 231, 183);
}

.center-trick {
  transform: translate(-50%, -50%);
  left: 50%;
}

.arrow-hover-animation:hover {
  animation: arrow-hover 0.5s 1 ease-in-out;
}

@keyframes arrow-hover {
  0% { transform: translateY(0px) }
  50% { transform: translateY(10px) }
  100% { transform: translateY(0px) }
}

.r-arrow-hover .arrow {
  transition-duration: 0.3s;
}

.r-arrow-hover:hover .arrow {
  transform: translateX(5px);
}

html {
  scroll-behavior: smooth;
}

.height-hero {
  height: 50vh;
}

.full-shadow {
  box-shadow: 0 0 4px #ccc;
}

.not-found-height {
  height: calc(100vh - 276px);
}

.scale-101 {
  transition-duration: 0.2s;
}

.scale-101:hover {
  transform: scale(1.01);
}